<template>
    <div class="contract">
        <TextBlock withTitle :id="7" />

        <div class="contract__sum" v-if="amount">
          <div class="contract__sum_economy" v-if="economy">
            <p class="info info_contract_title">{{ 'economy' | localize }}: <span class="info_contract_green">{{ economy.sum.toLocaleString() }} <span class="icon-₪"></span></span></p>
            <p class="info">{{ economy.text | localize }} <span v-html="economy.formula"></span></p>
            <br>
          </div>
          <p class="info info_contract_title info_contract_green">{{ 'contract_payment' | localize }}:</p>
          <br>
          <p class="info info_contract_text">{{ 'info_1' | localize }}</p>
          <p class="info info_contract_text">{{ 'info_2' | localize }}</p>
          <br>
          <p class="info info_contract_title">{{ 'contract_sum' | localize }}: <span class="info_contract_green">{{ service_amount.toLocaleString() }} <span class="icon-₪"></span></span></p>
          <p class="info info_contract_title" v-if="service.service_id == 1 || service.service_id == 3">{{ "contract_amount" | localize }}: <span class="info_contract_green">{{ percent.toLocaleString($store.state.lang) }}%</span> {{ 'contract_percent' | localize }} = <span class="info_contract_green">{{ amount.toLocaleString() }} <span class="icon-₪"></span></span></p>
          <p class="info info_contract_title" v-else-if="economy">{{ "contract_amount" | localize }}: <span class="info_contract_green">{{ percent.toLocaleString($store.state.lang) }}%</span> {{ 'contract_from_economy' | localize }} = <span class="info_contract_green">{{ amount_economy.toLocaleString() }} <span class="icon-₪"></span></span></p>
        </div>

        <div v-if="!serviceActive">
          <v-checkbox
              v-model="accepted"
              hide-details
              on-icon="icon-ckeckbox-on"
              off-icon="icon-ckeckbox-off"
              :class="{ invalid: $v.accepted.$dirty && $v.accepted.$invalid }"
          >
              <template v-slot:label>
              <input type="hidden" ref="accepted" />
              {{ "accept_contract" | localize }}
              </template>
          </v-checkbox>

          <button type="button" class="button button__form" :disabled="!accepted" :class="{ button__form_disabled: !accepted }" @click="submitContract">
              {{ "accepted" | localize }}
          </button>
        </div>
    </div>
</template>

<script>
import TextBlock from '@/components/Text.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Contract',
  components: { TextBlock },
  props: {
    service: { type: Object, default: null },
    backlink: { type: String, default: '/' }
  },
  data: () => ({
    accepted: 0,
    serviceActive: false,
    amount: null,
    amount_economy: null,
    service_amount: null,
    percent: null,
    economy: null
  }),
  validations: {
    accepted: { required, checked () { return this.accepted || false } }
  },
  watch: { 
    service () {
      if ('service_id' in this.service) {
        this.loadPaymentData()

        // Услуга активна = договор фиксирован
        this.serviceActive = this.service.status == 'active'
      }
    }
  },
  methods: {
    loadPaymentData () {
      const userId = this.$store.state.user.id || 0
      this.$store.commit('loading', true)
      this.axios
        .get('user_service/payment', {
          params: {
            user_id: userId,
            service_id: this.service.service_id
          }
        })
        .then((response) => {
          this.service_amount = response.data.service_amount
          this.amount = response.data.amount
          this.percent = response.data.percent
          this.economy = response.data.economy
          if (this.economy) {
            this.amount_economy = Math.round(parseInt(this.economy.sum || 0, 10) * this.percent / 100)
          }
          this.$store.commit('loading', false)
        })
        .catch(() => {
          this.error = 'server_error'
          this.$store.commit('loading', false)
        })
    },
    submitContract () {
      this.$store.commit('togglePopupText', { id: 19, withTitle: false, button: 'close', type: 'popup_info' })
    }
  }
}
</script>
