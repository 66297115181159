<template>
  <div class="my-documents">
    <div class="my-documents_block-name" v-if="byClients">
      <div class="my-documents_name">{{ user.name }}</div>
      <div class="my-documents_sort">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <div class="my-documents_sort_title" v-on="on">
              {{ "documents_sort" | localize }}
              <span class="icon-arrow-down my-documents_sort_icon"></span>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              class="my-documents_sort_item"
            >
              <v-list-item-title
                @click="sortDocuments(item.value)"
                class="my-documents_sort_item"
                >{{ item.title | localize }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="my-documents_no-documents" v-show="!filterDocuments.length">
      {{ "documents_other_filter" | localize }}
    </div>
    <div class="my-documents_block-documents" v-show="filterDocuments.length">
      <ItemDocument
        v-for="(item, index) in filterDocuments"
        :user="user"
        :document="item"
        :key="index"
        :check="checkDocuments"
      />
      <div class="container-button">
        <app-button
          labelBtn="send_documents"
          :onClick="sendDocuments"
          class="my-documents_button"
          :disabled="!check"
          v-show="filterDocuments.length"
        />
      </div>
    </div>

    <div class="popup popup_active" v-if="$store.state.popup.id">
        <div class="shadow"></div>
        <div class="popup__overlay popup__overlay_active">
            <div class="popup__center">
                <div class="popup__window popup__window_blue">
                    <div class="popup__logo"></div>
                    <div class="scroll__container">
                        <Contract @paymentDialog="contractConfirm" modal :service="service_id" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template>
      <div class="text-center">
        <v-dialog v-model="paymentDialog" width="600" height="600">
          <template v-slot:activator="{}"></template>
          <v-card>
            <PaymentService class="dialog__modal" modal :service="service_id" @confirm="paymentConfirm" />
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import ItemDocument from '@/components/profile/ItemDocument.vue'
import AppButton from '@/components/profile/AppButton'
import Contract from '@/components/Contract.vue'
import PaymentService from '@/components/PaymentService.vue'

export default {
  props: {
    user: { type: Object, default: null },
    documents: { type: Array, default: null },
    sortKey: { type: String, default: 'all' },
    filterChanged: { type: Function, default: null },
    filterChange: { type: Boolean, default: null },
    service_id: { type: Number },
    user_service_id: { type: Number }
  },
  components: { AppButton, ItemDocument, Contract, PaymentService },
  name: 'MyDocuments',
  data: () => ({
    items: [
      {
        title: 'documents_filter_all',
        value: 'all'
      },
      {
        title: 'documents_filter_done',
        value: 'done'
      },
      {
        title: 'documents_filter_not_done',
        value: 'notDone'
      }
    ],
    byClients: false,
    check: false,
    accepted: 0,
    paymentDialog: false,
    complete: false,
    filter: 'all'
  }),
  methods: {
    sendDocuments () {
      this.$store.commit('loading', true)
      this.check = false
      this.documents.forEach((item) => {
        if (item.status !== 'downloaded') return
        const formData = new FormData()
        formData.append('user_service_id', this.user_service_id)
        formData.append('document_id', item.id)
        formData.append('client_id', this.user.id || null)
        formData.append('status', 2)
        formData.append('path', item.path_img)
        this.axios
          .post('client_documents', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => {
            const success = response.data.success || false
            if (success) {
              // Документы загружены -> договор
              this.$router.push({ name: 'Contract', params: { service: this.service_id, backlink: '/profile/documents' } })
            }
          })
          .catch((e) => {
            this.error = 'server_error'
            this.$scrollTo('.error__container', 300, { offset: -400 })
          })
      })
      this.complete = true
      this.$store.commit('loading', false)
    },
    sortDocuments (value) {
      this.filter = value
    },
    contractConfirm () {
      this.paymentDialog = true
    },
    paymentConfirm () {
      // this.paymentDialog = true
    },
    checkDocuments () {
      this.check = this.documents.every((item) => item.status !== null)
    }
  },
  computed: {
    filterDocuments () {
      if (this.filter === 'done') {
        return this.documents.filter((item) => item.status !== null)
      } else if (this.filter === 'notDone') {
        return this.documents.filter((item) => item.status === null)
      }
      return this.documents
    }
  },
  beforeUpdate () {
    if (this.filterChange) {
      this.filter = 'all'
      this.filterChanged()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";
.my-documents {
  max-width: 710px;

  .my-documents_sort_item {
    cursor: default;
  }
  .container-button {
    width: 100%;

    .button {
      margin: 0;
    }
  }
  &_no-documents {
    font-size: 14px;
    line-height: 22px;
  }
  &_button {
    margin: 30px 0 40px;
  }
  &_block-name {
    display: flex;
    justify-content: space-between;
    cursor: default;
  }
  &_sort {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    .my-documents_sort_title {
      display: flex;
      line-height: 26px;
      align-items: center;
      cursor: pointer;
    }
    .icon-arrow-down::before {
      font-size: 10px;
      margin-left: 10px;
    }
  }
  &_name {
    font-size: 16px;
    line-height: 26px;
    display: flex;
    text-align: center;
    font-style: normal;
    font-weight: normal;
  }
  .my-documents_block-documents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

//media styles

@media screen and (max-width: 1024px) {
  .my-documents {
    max-width: none;

    .my-documents_block-documents {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 16px;
    }
    &_no-documents {
      margin-top: 20px;
    }
  }
}
@media screen and (min-width: 1025px) {
  .my-documents {
    &__block-documents {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 100%;
    }
    &_name {
      margin-bottom: 30px;
    }
  }
}
</style>
