<template>
  <div class="item-document" :class="{'item-document_loaded': getClassDocument(document.status)}">
    <div class="item-document_document-block">
      <div class="item-document_message">
        <div class="item-document_icon" @click="showMessage">
          <div class="item-document_icon_notification">
            <div class="item-document_icon_general icon-question" :class="getClassStatusMessage"></div>
          </div>
          <div class="item-document_icon_message"></div>
        </div>
        <div class="item-document_block-message" :class="getClassMessage">
          <div class="item-document_block-message_title">
            {{ "documents_comments_moderator" | localize }}
          </div>
          <div class="item-document_block-message_text">
            {{document.message}}
          </div>
        </div>
      </div>
      <div class="item-document_image" v-if="document.status !== null && document.path">
        <img class="item-document_image_document" :src="getImg" :alt="document.name" />
      </div>
      <div class="item-document_title">
        {{ document.name }}
      </div>
      <div class="item-document_status" v-if="document.status && document.status !== 'downloaded'">
        <div class="item-document_status-color" :class="getClassStatusIcon(document.status)"></div>
        <div class="item-document_status-text">{{ getStatus.title | localize}}</div>
      </div>
    </div>
    <div class="item-document_button" v-if="document.status !== 'received'" @click="updateDocument(getButton.functionButton, document.id)">
      <span class="icon-vector"></span>
      {{ getButton.title | localize }}
      <input
        ref="downloadImg"
        class="item-document_file"
        type="file"
        :name="document.value"
        accept="image/*"
        @change="documentDownload"
      />
    </div>
  </div>
</template>

<script>
import { statusDocument, documentButton } from '@/fixtures/profile'

export default {
  name: 'documentItem',
  props: {
    user: { type: Object, default: null },
    document: { type: Object, default: null },
    check: { type: Function, default: null }
  },
  data () {
    return {
      img: '',
      setAsRead: [],
      isMessage: false,
      message: false
    }
  },
  computed: {
    getStatus () {
      return statusDocument.find((item) => {
        if (this.document.status && this.document.status !== 'downloaded') {
          return item.value === this.document.status
        } else {
          return 'noDocs'
        }
      })
    },
    getImg () {
      if (this.document.status === 'downloaded') {
        return this.document.path
      }
      const filelink = process.env.VUE_APP_API_HOST + this.document.path
      return filelink.replaceAll('.', '_min.')
    },
    getClassDocument () {
      return (name) => {
        if (name) {
          return name
        } else {
          return null
        }
      }
    },
    getClassStatusIcon () {
      return (name) => {
        if (name) {
          return `item-document_status-color__${name}`
        } else {
          return 'noDocs'
        }
      }
    },
    getClassStatusMessage () {
      if (this.document.message) {
        return 'item-document_icon_has-message'
      } else {
        return 'item-document_icon_question'
      }
    },
    getClassMessage () {
      if (this.message) {
        return 'item-document_block-message_visible'
      } else {
        return 'item-document_block-message_not-visible'
      }
    },
    getButton () {
      return documentButton.find((item) => {
        if (this.document.status === null) {
          return item.functionButton === 'documentDownload'
        }
        return item.functionButton === 'documentDelete'
      })
    }
  },
  methods: {
    updateDocument (nameFunction, doc_id) {
      if (nameFunction === 'documentDownload') {
        this.clickInputImg()
      } else {
        this.documentDelete(doc_id)
      }
    },
    clickInputImg () {
      this.$refs.downloadImg.click()
    },
    documentDownload () {
      this.img = this.$refs.downloadImg.files[0]
      if (!this.img) {
        this.document.path = ''
        this.document.status = null
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(this.img)
        reader.onload = () => {
          this.document.path = reader.result
          this.document.path_img = this.img
        }
        this.document.status = 'downloaded'
      }
      this.check()
    },
    setRead (doc_id) {
      this.axios
        .put('client_documents/' + doc_id, { user_id: this.$store.state.user.id || 0, is_read: 1 })
        .then(() => {
          const notices = Object.assign({}, this.$store.state.notices)
          notices.docs--
          this.setAsRead.push(doc_id)
          this.$store.commit('setNotices', notices)
        })
    },
    documentDelete (doc_id) {
      this.$store.commit('loading', true)
      this.axios
        .delete('client_documents/' + doc_id, { params: { user_id: this.$store.state.user.id || 0 } })
        .then(() => {
          this.$store.commit('loading', false)
          this.document.imageSrc = ''
          this.document.status = null
          this.check()
        })
        .catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$store.commit('loading', false)
        })
    },
    showMessage () {
      if (this.document.message) {
        this.message = !this.message
      }
      if (!this.document.is_read && !this.setAsRead.includes(this.document.id)) {
        this.setRead(this.document.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";

.item-document {
  border: 1px solid $white;
  box-sizing: border-box;
  width: 330px;
  height: 220px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 50px;

  .item-document_document-block {
    height: 135px;
    padding: 50px 0 30px;
    margin: 0 30px;
    border-bottom: 1px solid $green;

    .item-document_message {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      .item-document_icon {
        margin-right: 10px;
        margin-top: 3px;
      }
      .item-document_icon_message {
        width: 39px;
        height: 39px;
      }
      .item-document_icon_notification {
        position: relative;
      }
      .item-document_icon_general {
        width: 40px;
        height: 40px;
        font-size: 40px;
        line-height: 40px;
        position: absolute;
        top: 10px;
        right: 10px;
        color: $white;
      }
      .item-document_icon_has-message {
        color: $green;
        cursor: pointer;
      }
      .item-document_block-message {
        position: relative;
        background: #020266;
        box-shadow: 0 4px 28px #0f3378, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 15px 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 8px;
        transition-duration: 0.5s;
        transition-property: width, height;
        z-index: 100;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          background: #020266;
          transform: rotate(45deg);
          position: absolute;
          right: 29px;
          top: -4px;
          z-index: 0;
          box-shadow: 0 4px 28px #0f3378, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &_title {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 10px;
        }
        &_text {
          font-size: 11px;
          line-height: 20px;
        }
        &_visible {
          width: 268px;
        }
        &_not-visible {
          width: 0;
          height: 0;
          display: none;
        }
      }
    }
  }
  &_file {
    display: none;
  }
  .accepted {
    border-top: 1px solid green;
    border-left: 1px solid green;
    border-right: 1px solid green;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .canceled {
    border-top: 1px solid #ff8e8e;
    border-left: 1px solid #ff8e8e;
    border-right: 1px solid #ff8e8e;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .verified {
    border-top: 1px solid #f2c94c;
    border-left: 1px solid #f2c94c;
    border-right: 1px solid #f2c94c;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .item-document_image {
    height: 95px;
    border-radius: 4px;

    img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
    &_document {
      object-fit: cover;
      object-position: center;
    }
  }
  &_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
  }
  &_status {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_status-text {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    margin-left: 5px;
  }
  &_status-color {
    border-radius: 50%;
    &__accepted {
      background: green;
    }
    &__canceled {
      background: #ff8e8e;
    }
    &__verified {
      background: #f2c94c;
    }
    &_noDocs {
      background: none;
    }
  }
  &_status-color {
    width: 10px;
    height: 10px;
  }
  .item-document_button {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;

    .icon-vector {
      width: 30px;
      height: 27px;
      font-size: 27px;
      border-bottom: 1px solid $white;
      text-align: center;
      position: absolute;
      left: 30px;
      bottom: 30px;
      vertical-align: top;

      &::before {
        transform: rotate(180deg);
        margin: 0;
      }
    }
  }

  &_loaded {
    .item-document_title {
      display: none;
    }
    .item-document_status {
      position: absolute;
      left: 30px;
      top: 20px;
    }

    .item-document_document-block {
      padding: 20px 0;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .item-document_button {
      .icon-vector {
        display: none;
      }
    }
  }
}

#app.light-theme {
  .item-document_status-text {
    color: $black;
  }
  .item-document {
    border: 1px solid $black;

    .item-document_button {
      .icon-vector {
        border-bottom: 1px solid $black;
      }
    }

    .item-document_document-block {
      border-bottom: 1px solid $green_dark;

      .item-document_message {
        .item-document_icon_general {
          color: $black;
        }
        .item-document_icon_has-message {
          color: $green_dark;
        }

        .item-document_block-message {
          background: $white;
          box-shadow: 0 4px 28px #999;
          color: $black;
          
          &::before {
            background: $white;
            box-shadow: none;
          }
        }
      }
    }
  }
}

#app.locale_heb {
  .item-document_status-text {
    margin-right: 5px;
  }
  .item-document {
    .item-document_button {
      .icon-vector {
        left: auto;
        right: 30px;
      }
    }

    .item-document_document-block .item-document_message {
      right: auto;
      left: 0;

      .item-document_block-message::before {
        right: auto;
        left: 20px;
      }

      .item-document_icon {
        margin-left: 10px;
        margin-right: 0;
      }

      .item-document_icon_general {
        right: auto;
        left: 10px;
      }
    }

    &_loaded {
      .item-document_status {
        left: auto;
        right: 30px;
      }
    }
  }
}

//media styles

@media screen and (max-width: 1024px) {
  .item-document {
    width: 100%;
    margin-bottom: 30px;
  }
  .item-document_image {
    height: 95px;
  }
}
</style>
